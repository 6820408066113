import { Controller } from "@hotwired/stimulus"
// Depends on https://github.com/ganlanyuan/tiny-slider
import { tns } from "tiny-slider/src/tiny-slider";

export default class extends Controller {
  static targets = ["container"];

  sliderObject;

  connect() {
    this.sliderObject = tns({
      container: this.containerTarget,
      items: 1,
      center: false,
      slideBy: 1,
      arrowKeys: true,
      controls: true,
      autoHeight: true,
      nav: false,
      navPosition: "bottom",
      loop: true,
      rewind: false,
      swipeAngle: 70,
      responsive: {
        0: { edgePadding: 1, gutter: 10 },
      },
    });

    // bind to index change event
    //this.sliderObject.events.on('indexChanged',this.onChange.bind(this));
  }

  navigate(e) {
    var p = this.element.querySelector(".tns-outer").getBoundingClientRect();
    var middleX = p.left + p.width / 2;

    if (e.clientY > p.top && e.clientY < p.bottom) {
      // left side click
      if (e.clientX >= p.left && e.clientX < middleX) {
        this.sliderObject.goTo("prev");
        // right side click
      } else if (e.clientX >= middleX && e.clientX < p.right) {
        this.sliderObject.goTo("next");
      }
    }
  }

  goTo(e) {
    e.preventDefault();
    var index = e.target.dataset.index;
    this.sliderObject.goTo(index);
    if (e.target.dataset.active) {
      e.target.classList.add("clr--accent");
      var navItems = this.element.querySelectorAll(".smilealigner-action-card a");
      navItems.forEach(function (item) {
        if (item.dataset.index != index) {
          item.classList.remove("clr--accent");
        }
      });
    }
  }

  onChange(info) {
    var index = info.index - 1;
  }

  disconnect() {
    this.sliderObject.destroy();
    this.sliderObject = null;
  }
}
