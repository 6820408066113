import mapboxgl from '!mapbox-gl';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content", "map"]

  connect() {
    this.initMap();
    this.resetTabs();

    this.selectLocation({target: this.tabTargets[0]})
  }

  initMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZm9ybXJhdXNjaCIsImEiOiJja211b2hmaHkxM2s0MndwbXQ1MHE2a2h5In0.FVBBWdSvAyyIJmtn-JcQtg';
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [7.590900697773807, 50.35402914315356],
      zoom: 15 // starting zoom
    });
    this.map.scrollZoom.disable();
  }

  resetTabs() {
    for( let tab of this.tabTargets)
      tab.classList.remove("active")

    for( let content of this.contentTargets)
      content.style = "display: none";
  }

  selectLocation(e) {
    const selectedTab = e.target.closest(".tab");
    const selectedContent = document.getElementById(selectedTab.dataset.location);

    this.resetTabs();
    selectedTab.classList.add("active");
    selectedContent.style = "display: block";

    const lat = parseFloat(selectedTab.dataset.locationLat);
    const lng = parseFloat(selectedTab.dataset.locationLng);
    this.map.setCenter([lng, lat]);
    this.setMarker(lng, lat);
  }

  initMarker(lng, lat) {
    const el = document.createElement('div');
    el.className = 'marker';
    this.marker = new mapboxgl.Marker(el, {
      anchor: 'bottom'
    }).setLngLat([lng, lat]).addTo(this.map);
  }

  setMarker(lng, lat) {
    if (this.ballonMarker != undefined)
      this.ballonMarker.setLngLat([lng, lat]);
    else
      this.initMarker(lng, lat);
  }

}
