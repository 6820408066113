import Cookies from "js-cookie"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    gaProperty: { type: String, default: "G-XRRQZY6RT4" },
  }
  static targets = ["banner"]

  connect() {

  }

  disconnect() {
  }

  enableTracking(e) {
    e.preventDefault();
    const isSecure = location.protocol === "https:";
    Cookies.set("cookie_eu_consented", true, { path: "/", expires: 365, secure: isSecure });
    this.hideBanner();
    this._reloadSite();
  }

  disableTracking(e) {
    e?.preventDefault();
    const isSecure = location.protocol === "https:";
    Cookies.set(`ga-disable-${this.gaPropertyValue}`, true, { path: "/", expires: 365, secure: isSecure })
    Cookies.set("cookie_eu_consented", false, { path: "/", expires: 365, secure: isSecure })
    this.hideBanner();
    this._reloadSite();
  }

  resetPreferences(e) {
    e.preventDefault();
    Cookies.remove("cookie_eu_consented");
    Cookies.remove(`ga-disable-${this.gaPropertyValue}`);
    this._reloadSite();
  }

  _reloadSite() {
    location.reload();
    // Turbo.visit(window.location.toString(), { action: "replace" }); // not reliable enough
  }

  hideBanner() {
    if (this.hasBannerTarget) {
      this.bannerTarget.classList.add("hidden")
    }
  }
}

